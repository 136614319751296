import ApiService from "@/core/services/api.service.js";

const offerService = {
    getOffers() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/offers")
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getOfferById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/offers/detail/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createOffer(data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/offers/create",data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateOffer(data,id) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/offers/update/"+id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteOffer(id) {
        return  new Promise((resolve, reject) => {
          ApiService.delete("api/admin/offers/delete/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getProductsByKeyword(key) {
      return  new Promise((resolve, reject) => {
        ApiService.get("api/admin/search/products/keyword?search="+key)
          .then(function(response) {
            if (response.data) {
              resolve(response.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            reject(error)
          });
      });
  },

  deleteProduct(offerId,productId) {
    return  new Promise((resolve, reject) => {
      ApiService.delete(`api/admin/offers/product/delete/${offerId}?product_id=`+productId)
        .then(function(response) {
          if (response.data) {
            resolve(response.data);
          } else {
            resolve("");
          }
        })
        .catch(function(error) {
          reject(error)
        });
    });
  },

  deleteAllProducts(offerId) {
    return  new Promise((resolve, reject) => {
      ApiService.delete(`api/admin/offers/product/all/delete/${offerId}`)
        .then(function(response) {
          if (response.data) {
            resolve(response.data);
          } else {
            resolve("");
          }
        })
        .catch(function(error) {
          reject(error)
        });
    });
  },

  deleteSelectedProducts(offerId,productIds) {
    return  new Promise((resolve, reject) => {
      ApiService.delete(`api/admin/offers/product/all/delete/${offerId}?product_ids=`+productIds)
        .then(function(response) {
          if (response.data) {
            resolve(response.data);
          } else {
            resolve("");
          }
        })
        .catch(function(error) {
          reject(error)
        });
    });
  },
  addProductsToOffer(id,data) {
    return  new Promise((resolve, reject) => {
      ApiService.post("api/admin/offers/add/products/"+id,data)
        .then(function(response) {
          if (response.data) {
            resolve(response.data);
          } else {
            resolve("");
          }
        })
        .catch(function(error) {
          reject(error)
        });
    });
},
applyOffer(id,data) {
  return  new Promise((resolve, reject) => {
    ApiService.post("api/admin/offers/update/applyStatus/"+id,data)
      .then(function(response) {
        if (response.data) {
          resolve(response.data);
        } else {
          resolve("");
        }
      })
      .catch(function(error) {
        reject(error)
      });
  });
},
}

export default offerService;